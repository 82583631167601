<template>
  <div class="row" v-if="entity">
    <div class="col col-md-8">
      <div class="column q-gutter-md">
        <base-add-button :label="$t('cibles.add_cible')" color="reseau"
          :to="{ name: 'cible-create' }" />

        <CibleCard v-for="cible in ciblesList" v-bind:key="cible.id" :cible="cible" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CibleCard } from '../../components/cibles'

export default {
  name: 'EntityCibles',
  components: { CibleCard },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (!store.getters['cibles/getFilters']) {
      store.dispatch('cibles/fetchFilters')
    }
  },

  computed: {
    ...mapGetters({
      entity: 'entities/getCurrentEntity',
      cibles: 'cibles/getCibles',
      filters: 'cibles/getFilters',
      favorites: 'favorites/getFavorites'
    }),

    ciblesList () {
      return this.cibles.map(cible => ({
        ...cible,
        actions: [
          // { label: this.$t('tasks.create_task'), payload: { name: 'task-create', params: { model_object: cible } } }
        ],
        is_favorite: !!this.favorites && !!this.favorites['cible'] &&
          this.favorites['cible'].some(favorite => favorite.id === cible.id),
        model: { model_type: 'groupeCible', model_id: cible.id }
      }))
    }
  },

  watch: {
    filters: {
      handler (newVal, oldVal) {
        this.fetchCibles()
      },
      deep: true
    }
  },

  mounted () {
    if (this.filters) {
      this.fetchCibles()
    }
  },

  methods: {
    getActionsForCible (cible) {
      return [
        { label: this.$t('cibles.edit_cible'), payload: { name: 'cible-show', params: { id: cible.id } } }
      ]
    },

    onMenuClick ({ name, params }) {
      this.$router.push({ name, params })
    },

    fetchCibles () {
      let params = {}
      this.filters.forEach(filter => { params[filter.id] = filter.value })

      return this.$store.dispatch('cibles/fetchCibles', {
        ...params,
        model_type: 'entity',
        model_id: this.$route.params.id,
        rowsPerPage: 0
      }).finally(() => { this.$q.loading.hide() })
    }
  }
}
</script>

<style>
</style>
